/* eslint-disable default-param-last */
import { deepClone, isJSON } from '@st/utils-js';
import { validate } from '../utils/uuid';
import Element from './Element';
import InternalReference from './InternalReference';
import MusterdokumentElementAttrs from './MusterdokumentElementAttrs';
import SubelementContentElement from './SubelementContentElement';
import TextContentElement from './TextContentElement';
import TextContentElementAttrs from './TextContentElementAttrs';
import ElementStatus from './enums/ElementStatus';
import ElementTyp from './enums/ElementTyp';

function getLevelKey(level) {
  return `level${(level)}`;
}

export default class MusterdokumentElement extends Element {
  /**
   *
   * @param {String} id UUID
   * @param {String} state value of ElementStatus
   * @param {MusterdokumentElementAttrs} attrs
   * @param {String} revision value of revision
   * @param {Array} content Array of SubelementContentElements and TextContentElements
   * @param {JSON} internalReference
   */
  constructor(
    id = null,
    state = ElementStatus.MODIFIABLE,
    attrs = new MusterdokumentElementAttrs(),
    revision,
    content = [],
    internalReference = {},
    similarities = {}
  ) {
    super(id, ElementTyp.MUSTERDOKUMENT, state, attrs, revision, content);
    this.internalReference = internalReference;
    this.similarities = similarities;
  }

  /**
   * Creates MusterdokumentElement from JSON.
   * If keys are missing then it will use the default values.
   * @param {JSON} value
   * @param {JSON} elements
   * @returns null if invalid
   */
  static fromJSON(value, elements = {}, similarities = {}) {
    if (!isJSON(value)) return null;

    const {
      id,
      state = ElementStatus.MODIFIABLE,
      attrs = {},
      content = [],
      revision = 0,
      internalReference = {}
    } = value;

    let localAttrs = new MusterdokumentElementAttrs();
    if (isJSON(attrs)) {
      localAttrs = MusterdokumentElementAttrs.fromJSON(attrs);
    }

    const refs = internalReference;
    if (isJSON(refs)) {
      Object.keys(refs).forEach((refId) => {
        refs[refId] = refs[refId].map((ref) => InternalReference.fromJSON({ ...ref, refId }));
      });
    }

    let localContent = [];
    if (Array.isArray(content)) {
      localContent = this.createContent(value, elements, [], [], similarities);
    }

    return new MusterdokumentElement(
      id,
      state,
      localAttrs,
      revision,
      localContent,
      refs,
      similarities
    );
  }

  static createContent(element, elements, result = [], ancestors = [], similarities = {}) {
    if (!element.content) return result;
    const tmpActualAncestors = [ ...ancestors ];
    const {
      id, type, refPathId, choice
    } = element;
    tmpActualAncestors.push({
      id,
      type,
      refPathId,
      choice
    });
    const elementSimilarities = similarities[element.id] || [];

    element.content.forEach((item) => {
      if (ElementTyp.isSubelement(item.type)) {
        const newElement = deepClone(elements[item.id]);
        if (newElement) {
          newElement.id = item.id;
          newElement.refPathId = item.refPathId;

          newElement.choice = item.choice;

          this.createContent(
            newElement,
            elements,
            result,
            tmpActualAncestors,
            similarities
          );
        } else {
          result.push(TextContentElement.fromJSON({
            state: ElementStatus.MODIFIABLE,
            type: ElementTyp.ABSCHNITT,
            attrs: new TextContentElementAttrs().toJSON(),
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    type: 'text',
                    text: 'Die Klausel wurde gelöscht und ist nicht mehr in der Datenbank vorhanden.'
                  }
                ]
              }
            ],
            ancestors: [
              ...tmpActualAncestors,
              {
                id: item.id,
                type: ElementTyp.KLAUSEL,
                refPathId: item.refPathId
              }
            ]
          }, []));
        }
      } if (ElementTyp.isAbschnitt(item.type) 
        || ElementTyp.isUeberschrift(item.type)
        || ElementTyp.isTabelle(item.type)
        || ElementTyp.isFormular(item.type)
      ) {
        let tmpChoice = item.choice || element.choice;
        if (tmpActualAncestors.length > 1) {
          const ancestorRefPathId = tmpActualAncestors[1].refPathId;
          const docId = tmpActualAncestors[0].id;

          if (docId) {
            const tmpSubElement = elements[docId]?.content.find(
              (content) => content.refPathId === ancestorRefPathId
            );

            tmpChoice = tmpSubElement.choice;
          }
        }
        const tmpItem = {
          ...item,
          choice: tmpChoice,
          ancestors: tmpActualAncestors
        };
        const sectionSimilarities = elementSimilarities.filter((sim) => sim.sectionId === item.id);
        result.push(TextContentElement.fromJSON(tmpItem, sectionSimilarities));
      }
    });

    return result;
  }

  /**
   * @returns MusterdokumentElement in JSON representation
   */
  toJSON() {
    let attrs = null;
    if (this.attrs instanceof MusterdokumentElementAttrs) {
      attrs = this.attrs.toJSON();
    } else {
      attrs = this.attrs;
    }

    const content = this.content.map((item) => {
      if (item instanceof SubelementContentElement
        || item instanceof TextContentElement
      ) {
        return item.toJSON();
      }
      return item;
    });

    const internalReference = {};
    Object.keys(this.internalReference).forEach((internalReferenceId) => {
      const tmpInternalReference = this.internalReference[internalReferenceId].map((ref) => {
        let tmpReturn = { ...ref };
        if (ref instanceof InternalReference) {
          tmpReturn = ref.toJSON();
        }
        return tmpReturn;
      });

      internalReference[internalReferenceId] = tmpInternalReference;
    });

    return {
      id: this.id,
      state: this.state,
      type: this.type,
      attrs,
      revision: this.revision,
      content,
      internalReference,
      similarities: this.similarities
    };
  }

  get sections() {
    return this.content.map((c) => ({ ...c, sectionId: c.id }));
  }

  get order() {
    return this.content.map((c) => c.id);
  }

  get labels() {
    const localCounter = {};
    let maxLevel;
    let currentAnchor;
    let labelVisible;

    const labels = {};

    this.sections.forEach((section) => {
      let { level } = section.attrs;

      const { type } = section;

      const showEntry = level !== 0;

      if (ElementTyp.isKlausel(type)) {
        if (level > 0) {
          currentAnchor = level;
          labelVisible = 1;
        } else labelVisible = 0;

        if (
          localCounter[getLevelKey(level)] === null
          || localCounter[getLevelKey(level)] === undefined
        ) {
          Array.from(
            {
              length: level + 1
            },
            (v, k) => {
              if (
                localCounter[getLevelKey(k)] === null
                || localCounter[getLevelKey(k)] === undefined
              ) localCounter[getLevelKey(k)] = 1;
              return null;
            }
          );
          if (level > 0) maxLevel = level;
        } else {
          localCounter[getLevelKey(level)] += 1;

          Array.from(
            {
              length: maxLevel + 1
            },
            (v, k) => {
              if (k > level) {
                delete localCounter[getLevelKey(k)];
              }
              return null;
            }
          );
        }
      } else {
        switch (type) {
        case ElementTyp.UEBERSCHRIFT:
          if (level > 0) {
            currentAnchor = level;
            labelVisible = 1;
          } else labelVisible = 0;
          if (
            localCounter[getLevelKey(level)] === null
                || localCounter[getLevelKey(level)] === undefined
          ) {
            Array.from(
              {
                length: level + 1
              },
              (v, k) => {
                if (
                  localCounter[getLevelKey(k)] === null
                      || localCounter[getLevelKey(k)] === undefined
                ) localCounter[getLevelKey(k)] = 1;
                return null;
              }
            );
            if (level > 0) maxLevel = level;
          } else {
            localCounter[getLevelKey(level)] += 1;

            Array.from(
              {
                length: maxLevel + 1
              },
              (v, k) => {
                if (k > level) {
                  delete localCounter[getLevelKey(k)];
                }
                return null;
              }
            );
          }
          break;
        case ElementTyp.ABSCHNITT:
        case ElementTyp.TABELLE:
        case ElementTyp.FORMULAR:
        case ElementTyp.KLAUSEL:
          level = currentAnchor ? level + currentAnchor : level;
          if (labelVisible && showEntry) {
            if (
              localCounter[getLevelKey(level)] === null
                  || localCounter[getLevelKey(level)] === undefined
            ) {
              Array.from(
                {
                  length: level + 1
                },
                (v, k) => {
                  if (
                    localCounter[getLevelKey(k)] === null
                        || localCounter[getLevelKey(k)] === undefined
                  ) localCounter[getLevelKey(k)] = 1;
                  return null;
                }
              );
              maxLevel = level;
            } else {
              localCounter[getLevelKey(level)] += 1;

              Array.from(
                {
                  length: maxLevel + 1
                },
                (v, k) => {
                  if (k > level) {
                    delete localCounter[getLevelKey(k)];
                  }
                  return null;
                }
              );
            }
          }
          break;
        default:
          break;
        }
      }

      delete localCounter.level0;

      const ordered = {};

      Object.keys(localCounter)
        .sort()
        .reduce((obj, key) => {
          ordered[key] = localCounter[key];
          return obj;
        }, {});

      const label = labelVisible && showEntry > 0
        ? JSON.stringify(Object.values(ordered).slice(0, level))
          .replace(/(\[|\])/g, '')
          .replaceAll(',', '.')
        : '';

      if (label.length > 0) {
        labels[section.refPathId] = label;
      }
    });

    return labels;
  }

  get validType() {
    return !!this.type
      && typeof this.type === 'string'
      && ElementTyp.isMusterdokument(this.type);
  }

  get validState() {
    return !!this.state
      && typeof this.state === 'string'
      && ElementStatus.isValidState(this.state);
  }

  get validAttrs() {
    return !!this.attrs
      && this.attrs instanceof MusterdokumentElementAttrs
      && this.attrs.valid;
  }

  get validContent() {
    return !!this.content
      && Array.isArray(this.content)
      && this.content.length > 0
      && this.content.every((subelement) => {
        const isTextContentElement = subelement instanceof TextContentElement;
        const isSubelementContentElement = subelement instanceof SubelementContentElement;

        return (isTextContentElement || isSubelementContentElement) && subelement.valid;
      });
  }

  get validInternalReference() {
    return isJSON(this.internalReference)
      && Object.keys(this.internalReference)
        .every((referenceId) => validate(referenceId))
      && Object.values(this.internalReference)
        .every((referenceArr) => Array.isArray(referenceArr) && referenceArr
          .every((reference) => reference instanceof InternalReference && reference.valid));
  }

  get valid() {
    const { validId } = this;

    const valid = validId
      && this.validType
      && this.validState
      && this.validAttrs
      && this.validContent
      && this.validInternalReference;

    return valid;
  }
}
